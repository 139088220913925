"use client";
import {
  BackgroundImage,
  Box,
  Button,
  Flex,
  Text,
  Title,
  VisuallyHidden,
} from "@mantine/core";
import Link from "next/link";
import React from "react";

export default function DesktopHero() {
  return (
    <Box>
      <BackgroundImage src={"/images/hero.webp"} alt="Discs in a grid">
        <Flex
          pt="200px"
          px="50px"
          justify="flex-start"
          direction="column"
          pb="100px"
        >
          <Title size={"4rem"}>Find the perfect disc golf disc!</Title>
          <Text
            w={"75%"}
            mt={"15px"}
            size={"1.5rem"}
            style={{ lineHeight: 1.3 }}
          >
            Welcome to Stacks on Stacks, the leading online disc finder and disc
            search tool. Our user-friendly disc golf search engine brings all
            your favorite retailers to one convenient place. Whether you're
            wanting to try new discs or search for that perfect putter,
            midrange, or driver, our advanced search filters help you find
            exactly what you're looking. Filter by brand, mold, plastic, weight,
            color, price, flight numbers and more. Stacks on Stacks is where
            precision meets ease, ensuring you find the exact disc you need to
            complete your bag.
          </Text>
          <VisuallyHidden>
            Find the best disc golf discs from all your favorite disc golf
            brands like AGL, Alfa Discs, Axiom, Birdie, Clash, DGA, Dino,
            Discmania, Discraft, Divergent Discs, Dynamic Discs, Elevation Disc
            Golf, Gateway, Hyzerbomb, Infinite Discs, Innova, Kastaplast,
            Launch, Legacy, Loft Discs, Lone Star Discs, MVP, Millennium, Mint
            Discs, Neptune Discs, Prodigy, Prodiscus, RPM Discs, Streamline,
            Daredevil, Latitude 64, Thought Space Athletics, Trash Panda, Viking
            Discs, Westside, XCOM, Yikun, and EV-7.
          </VisuallyHidden>
          <Link href={"/disc-search"} prefetch={false}>
            <Button
              variant="filled"
              color="var(--colors-bg-brand)"
              size="xl"
              w="300px"
              radius="sm"
              mr={"100px"}
              mt={"50px"}
            >
              <Title>Get Started</Title>
            </Button>
          </Link>
        </Flex>
      </BackgroundImage>
    </Box>
  );
}
