import { ComponentPropsWithoutRef } from "react";

function LogoLetter(props: ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      width="100%"
      height="34"
      viewBox="0 0 381.04 200.88"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Layer_1" data-name="Layer 1">
        <path
          className="cls-1"
          fill="var(--colors-brand)"
          d="M219.56,101.35q-1.8.81-3.57,1.53c-1.49.61-3,1.2-4.54,1.75.06-.56.11-1.12.14-1.69a12.07,12.07,0,0,0-3.13-8.49,10.77,10.77,0,0,0-7-4,11.66,11.66,0,0,0-8,1.63l-.26.17a15.75,15.75,0,0,0-2.37,2.15,5.21,5.21,0,0,0-.63-.48l-.15-.1a9.69,9.69,0,0,0-4.24-1.41,8.64,8.64,0,0,0-4.76.87l-.49.29a61.87,61.87,0,0,0-9.22,8.28,74,74,0,0,0-7.51,9.84l-.09.15A108.5,108.5,0,0,0,158.59,122a48.57,48.57,0,0,0-3.43,11.6,18,18,0,0,0-.48,4.25,12.22,12.22,0,0,0,.91,4.64,12.63,12.63,0,0,0,6.68,7.68,15,15,0,0,0,6.84,1.71,14.45,14.45,0,0,0,3.19-.36,38.61,38.61,0,0,0,8.08-2.82,25.41,25.41,0,0,0,7.72-5.38,75.1,75.1,0,0,0,5.68-6.53,126.5,126.5,0,0,0,8-11.27,20.78,20.78,0,0,0,2.38-3.55c.31-.59.63-1.15,1-1.7,1.19-.25,2.43-.54,3.77-.87,1.63-.42,3.1-.77,4.38-1.07l.24-.06c3.42-1.06,6.77-2.28,9.95-3.61.76-.32,1.53-.66,2.29-1C223.46,109.69,221.46,105.52,219.56,101.35Zm-30.45,15.12a68.35,68.35,0,0,1-4,5.83c-1.46,1.94-2.87,3.92-4.19,5.87-1.73,2-3.26,3.74-4.7,5.24a23.23,23.23,0,0,1-4.82,3.83l-.56.31c0-.72,0-1.61,0-2.72a41.87,41.87,0,0,1,2.77-8.53,57.94,57.94,0,0,1,4.54-8.29,90.28,90.28,0,0,1,8.64-11.19c0,.33,0,.67.06,1a14.42,14.42,0,0,0,2.72,7.73C189.47,115.83,189.29,116.14,189.11,116.47Z"
        />
        <path
          className="cls-1"
          fill="var(--colors-brand)"
          d="M240.14,100.07a6.68,6.68,0,0,0-3-3.59A9.35,9.35,0,0,0,232.65,95a7,7,0,0,0-4.52,1.1l-.3.23a11.91,11.91,0,0,1-2.73,1.86c-1.27.63-2.58,1.36-3.9,2.16l-4.77,2.38c1.65,4.31,2.85,8.6,4.95,12.74a54.23,54.23,0,0,0,8.19-3.7,76.21,76.21,0,0,0,8.72-5.94l.31-.3A5.38,5.38,0,0,0,240.14,100.07Z"
        />
        <path
          className="cls-2"
          d="M91,75.42q-.59-7.36-5.49-11.49T70.7,59.8a29.93,29.93,0,0,0-10.34,1.51,12.67,12.67,0,0,0-5.93,4.09,10,10,0,0,0-2,5.89,9.26,9.26,0,0,0,1,4.9,11.07,11.07,0,0,0,3.65,3.9,27.35,27.35,0,0,0,6.41,3.13,68,68,0,0,0,9.28,2.51l12.37,2.65a93.08,93.08,0,0,1,21.43,7,51.15,51.15,0,0,1,14.58,10.2,37.25,37.25,0,0,1,8.36,13.14,46.93,46.93,0,0,1,2.76,15.91q-.08,14.88-7.43,25.19t-21,15.69Q90.15,180.88,71,180.88q-19.66,0-34.29-5.82A47.49,47.49,0,0,1,14,157.13Q6,145,5.89,126.09H44.78a23,23,0,0,0,3.46,11.63A19.19,19.19,0,0,0,57,144.87a33.76,33.76,0,0,0,13.44,2.43,32.1,32.1,0,0,0,11-1.62,14.75,14.75,0,0,0,6.7-4.5,10.53,10.53,0,0,0,2.36-6.55,9.58,9.58,0,0,0-2.25-6.08,18.52,18.52,0,0,0-7.1-4.71,78.11,78.11,0,0,0-13.33-3.94l-15-3.24q-20-4.35-31.56-14.55T9.72,74.24a41.5,41.5,0,0,1,7.7-25.15Q25.19,38.29,39,32.26t31.7-6q18.27,0,31.6,6.11t20.58,17.2q7.26,11.09,7.33,25.89Z"
        />
        <path
          className="cls-2"
          d="M333.91,75.42q-.59-7.36-5.49-11.49T313.58,59.8a29.93,29.93,0,0,0-10.34,1.51,12.67,12.67,0,0,0-5.93,4.09,10,10,0,0,0-2,5.89,9.26,9.26,0,0,0,1,4.9,11.07,11.07,0,0,0,3.65,3.9,27.35,27.35,0,0,0,6.41,3.13,68,68,0,0,0,9.28,2.51L328,88.38a92.92,92.92,0,0,1,21.43,7A51.15,51.15,0,0,1,364,105.58a37.25,37.25,0,0,1,8.36,13.14,46.68,46.68,0,0,1,2.76,15.91q-.06,14.88-7.43,25.19t-21,15.69q-13.67,5.37-32.81,5.37-19.67,0-34.28-5.82a47.47,47.47,0,0,1-22.69-17.93q-8.07-12.12-8.14-31h38.89a23,23,0,0,0,3.46,11.63,19.19,19.19,0,0,0,8.73,7.15,33.76,33.76,0,0,0,13.44,2.43,32.1,32.1,0,0,0,11-1.62,14.83,14.83,0,0,0,6.71-4.5,10.58,10.58,0,0,0,2.35-6.55,9.62,9.62,0,0,0-2.24-6.08,18.64,18.64,0,0,0-7.11-4.71,78.11,78.11,0,0,0-13.33-3.94l-15-3.24q-20-4.35-31.55-14.55T252.6,74.24a41.5,41.5,0,0,1,7.7-25.15q7.77-10.8,21.58-16.83t31.7-6q18.27,0,31.6,6.11t20.58,17.2q7.26,11.09,7.33,25.89Z"
        />
      </g>
    </svg>
  );
}
export default LogoLetter;
