"use client";

import { Box } from "@mantine/core";
import { useCallback } from "react";
import { useMediaQuery } from "@mantine/hooks"; // Mantine's built-in hook

// Pre-import critical components
import DesktopHero from "./hero.desktop";
import MobileHero from "./hero.mobile";

export default function Hero() {
  const isMobile = useMediaQuery("(max-width: 768px)", false); // false is default value

  const HeroComponent = useCallback(
    () => (isMobile ? <MobileHero /> : <DesktopHero />),
    [isMobile]
  );

  return (
    <>
      <Box>
        <HeroComponent />
      </Box>
    </>
  );
}
