import { ComponentPropsWithoutRef } from "react";

function Mail(props: ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
        <path d="m3 7l9 6l9-6"></path>
      </g>
    </svg>
  );
}

export default Mail;
