"use client";
import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import Mail from "@/components/Icons/Mail";

export default function Contact() {
  return (
    <Container size="lg" mt="75px">
      <Card
        bg="var(--colors-yell)"
        c="var(--colors-dark)"
        shadow="md"
        radius="md"
        padding="50px"
      >
        <Flex gap="25%" align="center" justify="center" direction={"column"}>
          <Stack>
            <Title mt="sm" size="2rem" ta="center">
              Shape the Future with Us
            </Title>
            <Text fz="21px" ta="center">
              Got a brilliant idea for a new feature? Reach out and share your
              vision! Retailers, looking to join our platform? Let us know!
            </Text>
          </Stack>
        </Flex>
        <Center>
          <Button
            component="a"
            mt="25px"
            variant="outline"
            color="var(--colors-dark)"
            size="3rem"
            maw="350px"
            radius="sm"
            href="mailto:info@stacks-on-stacks.com"
            leftSection={<Mail style={{ fontSize: "28px" }} />}
          >
            <Title size="2rem">Contact Us</Title>
          </Button>
        </Center>
      </Card>
    </Container>
  );
}
