import React from "react";
import {Box, Button, Flex, Text, Title, VisuallyHidden} from "@mantine/core";
import Link from "next/link";

// Predefine all text content
const HERO_TITLE = "Find the perfect disc golf disc!";
const HERO_TEXT = `Welcome to Stacks on Stacks, the leading online disc finder and disc
search tool. Our user-friendly disc golf search engine brings all
your favorite retailers to one convenient place. Whether you're
wanting to try new discs or search for that perfect putter,
midrange, or driver, our advanced search filters help you find
exactly what you're looking. Filter by brand, mold, plastic, weight,
color, price, flight numbers and more. Stacks on Stacks is where
precision meets ease, ensuring you find the exact disc you need to
complete your bag.`;

const VISUALLY_HIDDEN_TEXT = `Find the best disc golf discs from all your favorite disc golf
brands like AGL, Alfa Discs, Axiom, Birdie, Clash, DGA, Dino,
Discmania, Discraft, Divergent Discs, Dynamic Discs, Elevation Disc
Golf, Gateway, Hyzerbomb, Infinite Discs, Innova, Kastaplast,
Launch, Legacy, Loft Discs, Lone Star Discs, MVP, Millennium, Mint
Discs, Neptune Discs, Prodigy, Prodiscus, RPM Discs, Streamline,
Daredevil, Latitude 64, Thought Space Athletics, Trash Panda, Viking
Discs, Westside, XCOM, Yikun, and EV-7.`;

export default function MobileHero() {
  return (
    <Box>
      <Flex
        pt="60px"
        justify="center"
        align="center"
        direction="column"
        pb="50px"
      >
        <Title size="4rem" align="center">
          {HERO_TITLE}
        </Title>
        <Text
          px="25px"
          mt="15px"
          size="18px"
          style={{ lineHeight: 1.3 }}
          align="center"
        >
          {HERO_TEXT}
        </Text>
        <VisuallyHidden>{VISUALLY_HIDDEN_TEXT}</VisuallyHidden>
        <Link href="/disc-search" passHref>
          <Button
            component="a"
            variant="filled"
            color="var(--colors-bg-brand)"
            size="3rem"
            styles={{ root: { width: "300px", marginTop: "50px" } }}
            radius="sm"
          >
            <Title>Get Started</Title>
          </Button>
        </Link>
      </Flex>
    </Box>
  );
}
