// data/marquee-data.ts

// Types for metadata
type RetailerMetadata = {
    fullName: string;
    priority: number;
    baseUrl?: string;
};

type BrandMetadata = {
    fullName: string;
    priority: number;
    established?: number;
};

export const retailers = [
    "3cdiscs",
    "aloftdg",
    "appalachiandiscgolf",
    "birdiediscgolfsupply",
    "blackinkdiscs",
    "breakingaces",
    "chumbadiscs",
    "corediscgolf",
    "deadstraightdiscs",
    "dfxdiscs",
    "discgod",
    "discgolf978",
    "discgolfdealsusa",
    "discgolftreasures",
    "discimusprime",
    "doublehelixdiscsports",
    "dzdiscs",
    "elementaldg",
    "familydiscgolf",
    "flightfactorydiscs",
    "foundationdiscs",
    "gatewaydiscsports",
    "gemdiscs",
    "golfdisco",
    "gravitydiscgolf",
    "griplocked",
    "lightningdiscgolf",
    "luckydiscgolf",
    "naileditdiscgolf",
    "optiondiscs",
    "otbdiscs",
    "outerlimitsdg",
    "prodigydisc",
    "pureflightdiscgolf",
    "rareairdiscs",
    "rarediscgolf",
    "reaperdiscs",
    "reloaddiscs",
    "roguediscs",
    "scissortaildiscgolf",
    "skybreed-discs",
    "skylinediscs",
    "smokingchainsdiscs",
    "thediscbarn",
    "throwshop",
    "treemagnets",
    "yeetstreetdiscs",
] as const;

export const brands = [
    "AGL",
    "Alfa",
    "Axiom",
    "Birdie",
    "Clash",
    "Daredevil",
    "DGA",
    "Dino",
    "Discmania",
    "Discraft",
    "Divergent Discs",
    "Dynamic Discs",
    "Elevation Disc Golf",
    "EV-7",
    "Gateway",
    "Hyzerbomb",
    "Infinite Discs",
    "Innova",
    "Kastaplast",
    "Latitude 64",
    "Launch",
    "Legacy",
    "Loft Discs",
    "Lone Star Discs",
    "Millennium",
    "Mint Discs",
    "MVP",
    "Neptune Discs",
    "Prodigy",
    "Prodiscus",
    "RPM Discs",
    "Streamline",
    "Thought Space Athletics",
    "Trash Panda",
    "Viking Discs",
    "Westside",
    "XCOM",
    "Yikun",
] as const;

// Create types from the arrays
export type RetailerSlug = typeof retailers[number];
export type BrandSlug = typeof brands[number];

// Metadata for retailers
export const retailerMetadata: Record<RetailerSlug, RetailerMetadata> = {
    "3cdiscs": {
        fullName: "3C Discs",
        priority: 1,
        baseUrl: "https://3cdiscs.com"
    },
    "aloftdg": {
        fullName: "A Loft Disc Golf",
        priority: 1,
        baseUrl: "https://aloftdg.com"
    },
    "appalachiandiscgolf": {
        fullName: "Appalachian Disc Golf",
        priority: 2,
        baseUrl: "https://appalachiandiscgolf.com"
    },
    "birdiediscgolfsupply": {
        fullName: "Birdie Disc Golf Supply",
        priority: 2
    },
    "blackinkdiscs": {
        fullName: "Black Ink Discs",
        priority: 2
    },
    "breakingaces": {
        fullName: "Breaking Aces",
        priority: 2
    },
    "chumbadiscs": {
        fullName: "Chumba Discs",
        priority: 2
    },
    "corediscgolf": {
        fullName: "Core Disc Golf",
        priority: 2
    },
    "deadstraightdiscs": {
        fullName: "Dead Straight Discs",
        priority: 2
    },
    "dfxdiscs": {
        fullName: "DFX Discs",
        priority: 2
    },
    "discgod": {
        fullName: "Disc God",
        priority: 2
    },
    "discgolf978": {
        fullName: "Disc Golf 978",
        priority: 2
    },
    "discgolfdealsusa": {
        fullName: "Disc Golf Deals USA",
        priority: 1
    },
    "discgolftreasures": {
        fullName: "Disc Golf Treasures",
        priority: 2
    },
    "discimusprime": {
        fullName: "Discimus Prime",
        priority: 2
    },
    "doublehelixdiscsports": {
        fullName: "Double Helix Disc Sports",
        priority: 2
    },
    "dzdiscs": {
        fullName: "DZ Discs",
        priority: 2
    },
    "elementaldg": {
        fullName: "Elemental Disc Golf",
        priority: 2
    },
    "familydiscgolf": {
        fullName: "Family Disc Golf",
        priority: 2
    },
    "flightfactorydiscs": {
        fullName: "Flight Factory Discs",
        priority: 1
    },
    "foundationdiscs": {
        fullName: "Foundation Discs",
        priority: 1
    },
    "gatewaydiscsports": {
        fullName: "Gateway Disc Sports",
        priority: 1
    },
    "gemdiscs": {
        fullName: "Gem Discs",
        priority: 2
    },
    "golfdisco": {
        fullName: "Golf Disco",
        priority: 2
    },
    "gravitydiscgolf": {
        fullName: "Gravity Disc Golf",
        priority: 2
    },
    "griplocked": {
        fullName: "Grip Locked",
        priority: 2
    },
    "lightningdiscgolf": {
        fullName: "Lightning Disc Golf",
        priority: 2
    },
    "luckydiscgolf": {
        fullName: "Lucky Disc Golf",
        priority: 1
    },
    "naileditdiscgolf": {
        fullName: "Nailed It Disc Golf",
        priority: 2
    },
    "optiondiscs": {
        fullName: "Option Discs",
        priority: 2
    },
    "otbdiscs": {
        fullName: "OTB Discs",
        priority: 1
    },
    "outerlimitsdg": {
        fullName: "Outer Limits Disc Golf",
        priority: 2
    },
    "prodigydisc": {
        fullName: "Prodigy Disc",
        priority: 1
    },
    "pureflightdiscgolf": {
        fullName: "Pure Flight Disc Golf",
        priority: 2
    },
    "rareairdiscs": {
        fullName: "Rare Air Discs",
        priority: 2
    },
    "rarediscgolf": {
        fullName: "Rare Disc Golf",
        priority: 2
    },
    "reaperdiscs": {
        fullName: "Reaper Discs",
        priority: 2
    },
    "reloaddiscs": {
        fullName: "Reload Discs",
        priority: 2
    },
    "roguediscs": {
        fullName: "Rogue Discs",
        priority: 2
    },
    "scissortaildiscgolf": {
        fullName: "Scissortail Disc Golf",
        priority: 2
    },
    "skybreed-discs": {
        fullName: "Skybreed Discs",
        priority: 1
    },
    "skylinediscs": {
        fullName: "Skyline Discs",
        priority: 2
    },
    "smokingchainsdiscs": {
        fullName: "Smoking Chains Discs",
        priority: 2
    },
    "thediscbarn": {
        fullName: "The Disc Barn",
        priority: 2
    },
    "throwshop": {
        fullName: "Throw Shop",
        priority: 1
    },
    "treemagnets": {
        fullName: "Tree Magnets",
        priority: 2
    },
    "yeetstreetdiscs": {
        fullName: "Yeet Street Discs",
        priority: 2
    }
};

// Metadata for brands
export const brandMetadata: Record<BrandSlug, BrandMetadata> = {
    "AGL": {
        fullName: "Above Ground Level",
        priority: 2,
        established: 2018
    },
    "Alfa": {
        fullName: "Alfa Discs",
        priority: 2,
        established: 2020
    },
    "Axiom": {
        fullName: "Axiom Discs",
        priority: 1,
        established: 2014
    },
    "Birdie": {
        fullName: "Birdie Disc Golf",
        priority: 2
    },
    "Clash": {
        fullName: "Clash Discs",
        priority: 2
    },
    "Daredevil": {
        fullName: "Daredevil Discs",
        priority: 2
    },
    "DGA": {
        fullName: "Disc Golf Association",
        priority: 1,
        established: 1976
    },
    "Dino": {
        fullName: "Dino Discs",
        priority: 2
    },
    "Discmania": {
        fullName: "Discmania",
        priority: 1,
        established: 2006
    },
    "Discraft": {
        fullName: "Discraft",
        priority: 1,
        established: 1979
    },
    "Divergent Discs": {
        fullName: "Divergent Discs",
        priority: 2
    },
    "Dynamic Discs": {
        fullName: "Dynamic Discs",
        priority: 1,
        established: 2005
    },
    "Elevation Disc Golf": {
        fullName: "Elevation Disc Golf",
        priority: 2
    },
    "EV-7": {
        fullName: "EV-7",
        priority: 2
    },
    "Gateway": {
        fullName: "Gateway Disc Sports",
        priority: 1,
        established: 1994
    },
    "Hyzerbomb": {
        fullName: "Hyzerbomb",
        priority: 2
    },
    "Infinite Discs": {
        fullName: "Infinite Discs",
        priority: 1
    },
    "Innova": {
        fullName: "Innova Champion Discs",
        priority: 1,
        established: 1983
    },
    "Kastaplast": {
        fullName: "Kastaplast",
        priority: 1,
        established: 2011
    },
    "Latitude 64": {
        fullName: "Latitude 64°",
        priority: 1,
        established: 2005
    },
    "Launch": {
        fullName: "Launch Discs",
        priority: 2
    },
    "Legacy": {
        fullName: "Legacy Discs",
        priority: 1
    },
    "Loft Discs": {
        fullName: "Loft Discs",
        priority: 2
    },
    "Lone Star Discs": {
        fullName: "Lone Star Discs",
        priority: 2
    },
    "Millennium": {
        fullName: "Millennium Golf Discs",
        priority: 1,
        established: 1999
    },
    "Mint Discs": {
        fullName: "Mint Discs",
        priority: 2
    },
    "MVP": {
        fullName: "MVP Disc Sports",
        priority: 1,
        established: 2010
    },
    "Neptune Discs": {
        fullName: "Neptune Discs",
        priority: 2
    },
    "Prodigy": {
        fullName: "Prodigy Disc",
        priority: 1,
        established: 2013
    },
    "Prodiscus": {
        fullName: "Prodiscus",
        priority: 2
    },
    "RPM Discs": {
        fullName: "RPM Discs",
        priority: 2
    },
    "Streamline": {
        fullName: "Streamline Discs",
        priority: 1
    },
    "Thought Space Athletics": {
        fullName: "Thought Space Athletics",
        priority: 2
    },
    "Trash Panda": {
        fullName: "Trash Panda Disc Golf",
        priority: 2
    },
    "Viking Discs": {
        fullName: "Viking Discs",
        priority: 2
    },
    "Westside": {
        fullName: "Westside Discs",
        priority: 1
    },
    "XCOM": {
        fullName: "XCOM Discs",
        priority: 2
    },
    "Yikun": {
        fullName: "Yikun Discs",
        priority: 2
    }
};

// Helper functions
export const getPriorityRetailers = (minPriority: number = 1) =>
    retailers.filter(retailer => retailerMetadata[retailer].priority <= minPriority);

export const getPriorityBrands = (minPriority: number = 1) =>
    brands.filter(brand => brandMetadata[brand].priority <= minPriority);

export const getFullName = (slug: RetailerSlug | BrandSlug, type: 'retailer' | 'brand'): string => {
    if (type === 'retailer') {
        return retailerMetadata[slug as RetailerSlug].fullName;
    }
    return brandMetadata[slug as BrandSlug].fullName;
};
