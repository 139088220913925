"use client";

import { memo, lazy, Suspense } from "react";
import { Box, Center, Text, Image } from "@mantine/core";

// Import Marquee lazily since it's a third-party component
const Marquee = lazy(() => import("react-fast-marquee"));

// Move data to separate file: data/marquee-data.ts
import { retailers, brands } from "./marquee-data";

// Memoized image component to prevent unnecessary re-renders
const LogoImage = memo(({ item, type }) => (
  <a href={`/disc-search?${type}=${item}`}>
    <Image
      width={100}
      height={50}
      fit="contain"
      loading="lazy"
      sizes="(max-width: 768px) 10vw"
      src={`/images/${type === "site" ? "r_logos" : "b_logos"}/${item}.svg`}
      style={{
        margin: "0 25px",
        marginTop: 80,
        height: "50px",
        objectFit: "contain",
      }}
      alt={`${item} logo`}
    />
  </a>
));

LogoImage.displayName = "LogoImage";

// Memoized marquee row component
const MarqueeRow = memo(({ items, direction, speed, type }) => (
  <Suspense fallback={<Center>Loading...</Center>}>
    <Marquee
      autoFill={true}
      play={true}
      pauseOnHover={false}
      pauseOnClick={false}
      speed={speed}
      direction={direction}
      loop={0}
      gradient={true}
      gradientColor={[253, 255, 127]} // Matches bg color #fdff7f
      gradientWidth={50}
    >
      {items.map((item) => (
        <LogoImage key={item} item={item} type={type} />
      ))}
    </Marquee>
  </Suspense>
));

MarqueeRow.displayName = "MarqueeRow";

// Main component using intersection observer for lazy loading
const FeatureMarquee = () => {
  return (
    <Box
      bg="#fdff7f"
      pt="50px"
      pb="75"
      component="section"
      aria-label="Featured retailers and brands"
    >
      <Center>
        <Text
          c="dark"
          size="1rem"
          mb="-25"
          align="center"
          tt="uppercase"
          component="h2"
        >
          Search discs from your favorite retailers:
        </Text>
      </Center>

      <MarqueeRow items={retailers} direction="left" speed={35} type="site" />

      <Center>
        <Text
          c="dark"
          size="1rem"
          pt="50px"
          mb="-25"
          tt="uppercase"
          component="h2"
        >
          Filter by all your favorite brands:
        </Text>
      </Center>

      <MarqueeRow items={brands} direction="right" speed={45} type="brand" />
    </Box>
  );
};

export default memo(FeatureMarquee);
